<template>
    <div class="task-assign">
        <div v-if="scope" class="task-assign__form">
            <InstanceInfo :name="scope.name" :date="scopeUpdatedInfo" :name-disabled="true"/>

            <div class="task-assign__field">
                <FieldTitleWithCounter title="The assigned package for this contract is:" :counter="1"/>
                <CNSelect :options="packagesList" label="Selected package" v-model="form.folder_id"
                          :aria-disabled="isScopeContractSent"/>
            </div>

            <div class="task-assign__field">
                <FieldTitleWithCounter title="The company and the signer for this contract are:" :counter="2"/>
                <CNSelect :options="[scope.external_company.name]" v-model="scope.external_company.name"
                          label="Company Name (Sub)" :aria-disabled="true"/>
                <CNSelect :caret="true" :searchable="true" :invalid="getFieldValidationError('external_assignee_id')"
                          :error="getFieldValidationError('external_assignee_id')"
                          @change="deleteFieldValidationError('external_assignee_id')"
                          :options="externalAssigneeList" v-model="form.external_assignee_id" label="Default Assignee"/>
                <CNSelect :caret="true" :searchable="true" :invalid="getFieldValidationError('external_signer_id')"
                          :error="getFieldValidationError('external_signer_id')"
                          @change="deleteFieldValidationError('external_signer_id')"
                          :options="externalSignersList" v-model="form.external_signer_id"
                          label="Default Signer Name (Sub)"/>
            </div>

            <div class="task-assign__field task-assign__field_last">
                <FieldTitleWithCounter :counter="3"
                                       title="Your name should be displayed below. Change the signer if you believe you are not the right assignee for this contract."/>
                <CNSelect :aria-disabled="true" label="Company Name (GC)" :options="[scope.internal_company.name]"
                          v-model="scope.internal_company.name"/>
                <CNSelect :caret="true" :searchable="true" :invalid="getFieldValidationError('internal_assignee_id')"
                          :error="getFieldValidationError('internal_assignee_id')"
                          @change="deleteFieldValidationError('internal_assignee_id')"
                          :options="internalAssigneeList" label="Default Assignee" v-model="form.internal_assignee_id"/>
                <CNSelect :caret="true" :searchable="true" :invalid="getFieldValidationError('internal_signer_id')"
                          :error="getFieldValidationError('internal_signer_id')"
                          @change="deleteFieldValidationError('internal_signer_id')"
                          :options="internalSignersList" v-model="form.internal_signer_id"
                          label="Default Signer Name (GC)"/>
            </div>

            <StickyFooter>
                <CButton variant="outline" color="primary" @click="submit('draft')">Save draft</CButton>

                <CButton color="primary" @click="submit(false)">
                    Save & next
                </CButton>
            </StickyFooter>

        </div>
        <div class="task-assign__loading" v-else>
            <CSpinner color="primary"/>
        </div>
    </div>
</template>

<script>

import InstanceInfo from "@/components/Forms/Partials/InstanceInfo.vue";
import FieldTitleWithCounter from "@/components/Forms/Partials/FieldTitleWithCounter.vue";
import CNSelect from "@/components/ui/CNSelect/CNSelect.vue";
import moment from "moment/moment";
import StickyFooter from "@/components/Forms/Partials/StickyFooter.vue";
import Scopes from "@/api/v2/endpoints/Scopes";
import TemplateFolders from "@/api/v2/endpoints/TemplateFolders";
import apiErrorHandler from "@/mixin/apiErrorHandler";
import pushCommitmentHelper from "@/mixin/scopes/pushCommitmentHelper";

export default {
    name: "TaskAssign",
    components: {StickyFooter, CNSelect, FieldTitleWithCounter, InstanceInfo},
    mixins: [apiErrorHandler, pushCommitmentHelper],
    inject: ['toast'],
    data() {
        return {
            componentMounted: false,

            form: {
                external_assignee_id: '',
                external_signer_id: '',
                internal_assignee_id: '',
                internal_signer_id: '',
                folder_id: '',
            },

            scope: null,
            templateFolders: [],
        }
    },
    computed: {
        isScopeContractSent() {
            return this.scope.status === 'contract sent';
        },
        scopeUpdatedInfo() {
            const date = moment(this.scope.last_activity.created_at).format('MM/DD/YYYY, HH:mm A');

            return `Last Updated by ${this.scope.last_activity.user_name} ${date}`;
        },
        packagesList() {
            return this.templateFolders.map(folder => ({
                label: folder.name,
                name: folder.name,
                value: folder.id
            }))
        },
        externalAssigneeList() {
            return this.scope.external_company.company_assigner_users
                .map(user => ({
                    value: user.id,
                    label: user.full_name,
                    name: user.full_name
                }));
        },
        externalSignersList() {
            return this.scope.external_company.company_signer_users
                .map(user => ({
                    value: user.id,
                    label: user.full_name,
                    name: user.full_name
                }));
        },
        internalAssigneeList() {
            return this.scope.internal_company.company_assigner_users
                .map(user => ({
                    value: user.id,
                    label: user.full_name,
                    name: user.full_name
                }));
        },
        internalSignersList() {
            return this.scope.internal_company.company_signer_users
                .map(user => ({
                    value: user.id,
                    label: user.full_name,
                    name: user.full_name
                }))
        }
    },
    async mounted() {
        this.componentMounted = true;
        await this.loadTemplateFolders();
        this.loadScopeData();
    },
    methods: {
        async loadTemplateFolders() {
            await TemplateFolders
                .index({
                    project_id: this.$route.params.id,
                    template_folder_data: 'id,name'
                })
                .then(response => this.templateFolders = response.data)
        },
        loadScopeData() {
            Scopes
                .show(this.$route.params.scopeId, {
                    scope_data: 'id,name,status,step,folder_id,external_assignee_id,external_signer_id,internal_assignee_id,internal_signer_id',
                    include_internal_company: 'id,name,default_contract_signer_id,default_task_assignee_id',
                    include_external_company: 'id,name,default_contract_signer_id,default_task_assignee_id',
                    include_company_signer_users: 'id,full_name',
                    include_company_assigner_users: 'id,full_name',
                    include_last_activity: 'all',
                })
                .then(async response => {
                    await this.checkPushCommitmentStep(response.data.step);

                    this.scope = response.data;
                    this.form.folder_id = this.scope.folder_id;
                    this.form.external_assignee_id = this.scope.external_assignee_id || this.scope.external_company.default_task_assignee_id;
                    this.form.external_signer_id = this.scope.external_signer_id || this.scope.external_company.default_contract_signer_id;
                    this.form.internal_assignee_id = this.scope.internal_assignee_id || this.scope.internal_company.default_task_assignee_id;
                    this.form.internal_signer_id = this.scope.internal_signer_id || this.scope.internal_company.default_contract_signer_id;

                    this.$emit('lastAvailableStep', this.scope.step)
                })
        },
        submit(action) {
            let data = Object.assign({}, this.form);
            data.is_draft = action === 'draft';

            Scopes
                .pushCommitmentStepOne(this.$route.params.scopeId, data)
                .then(() => {
                    this.toast('success', 'Changes have been saved successfully!');

                    if (!data.is_draft)
                        this.$router.push({name: 'ProjectScopesTaskReview'});
                })
                .catch(response => this.handleApiError(response))
        }
    }
}
</script>

<style lang="scss">
.task-assign {
    background: white;
    padding: 24px;
    border-radius: 8px;
    margin-top: 24px;

    &__loading {
        display: flex;
        justify-content: center;
    }

    &__field {
        display: flex;
        flex-direction: column;
        gap: 16px;

        padding-bottom: 16px;
        border-bottom: 1px solid #DEE4E8;

        & .field-title__label {
            max-width: 616px;
        }

        &_last {
            border-bottom: none !important;
        }
    }

    &__form {
        display: flex;
        flex-direction: column;
        gap: 16px;

        width: 744px;
    }

    & .multiselect {

        &-dropdown {
            overflow-y: auto;
        }
    }
}
</style>
