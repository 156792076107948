<template>
  <div class="field-title">
    <div class="field-title__info">
      <span class="field-title__counter">{{ counter }}</span>
      <span class="field-title__label">{{ title }}</span>
    </div>
  </div>
</template>

<script>

export default {
  name: "FieldTitleWithCounter",
  props: {
    title: {type: String, default: ''},
    counter: {type: Number, default: 0},
  }
}
</script>

<style scoped lang="scss">
.field-title {

  &__info {
    display: flex;
    gap: 8px;
    align-items: flex-start;
  }

  &__label {
    font-size: 16px;
    color: #1C262F;
  }

  &__counter {
    flex-shrink: 0;
    background: #0068ad;
    border-radius: 50%;
    -moz-border-radius: 50%;
    -webkit-border-radius: 50%;
    color: #fff;
    display: inline-block;
    font-size: 10px;
    font-weight: bold;
    line-height: 16px;
    text-align: center;
    width: 16px;
    transform: translateY(3px);
  }
}
</style>
